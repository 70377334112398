import React from 'react';
import classes from "./styles/landing-hero.module.css";
import CartoonButton from "../../components/CartoonButton/CartoonButton";
import helper from "../../constants/helper";

const LandingHero = ({metrics}) => {

  const statistics = [
    {
      title: "11",
      subtitle: "Blockchains"
    },
    {
      title: metrics.uaw || " 3000+",
      subtitle: "Players"
    },
    {
      title: metrics.transactions || "70 000+",
      subtitle: "Transactions"
    },
    {
      title: "$30 000",
      subtitle: "Rewards Won"
    },
  ]

  return (
    <>
      <div className={classes.wrapper}>
        <h1>Welcome to play-to-earn strategic NFT game</h1>
        <p><b>A free, play&fun multichain game inspired by the legendary 'Heroes Might and Magic', featuring AI, cash rewards & NFTs through strategic battles or lucky draws</b></p>
        <p>Are you ready to enter the arena and test your mettle against the greatest heroes in the land? The fate of
          the realm is in your hands!</p>
        <a href={helper.PLAY_URL} target={"_blank"}>
          <CartoonButton>PLAY FREE NOW</CartoonButton>
        </a>
      </div>
      <div className={classes.statistics}>
        {statistics.map(i => (
          <CartoonButton size={"big"}>
            <div className={classes.buttonContent}>
              <span>{i.title}</span>
              <span className={classes.subtitle}>{i.subtitle}</span>
            </div>
          </CartoonButton>
        ))}
      </div>
    </>
  );
};

export default LandingHero;
